import './scss/_common.scss';

import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/alert';

import backToTop from './components/backToTop.js';
import loadPlayer from './components/player.js';
import autoComplete from 'javascript-autocomplete/auto-complete.js';

import $ from 'jquery';
global.jQuery = $;
window.jQuery = $; window.$ = $;

backToTop();
loadPlayer();

let xhr;
new autoComplete({
    selector: 'input.search-form__input',
    minChars: 2,
    source: function(term, response){
        try { xhr.abort(); } catch(e){}
            xhr = $.getJSON(Urls['cpf_select'](),
            { q: term },
            function(data){ response(data); });
    },
    onSelect: function(e, term, item){
        document.getElementById('search-form').submit();
    }
});

document.addEventListener("DOMContentLoaded", function(event) {

  document.querySelectorAll('.login-form-password__button').forEach(btn => {
    btn.addEventListener('click', function (event) {
      const show = +this.getAttribute('data-show');
      const field = this.parentNode.parentNode.querySelector('.login-form-password__input');
      const icon = this.querySelector('.icon');
      if (show) {
        // сейчас пароль видно
        field.setAttribute('type', 'password');
        this.setAttribute('data-show', '0');
        this.setAttribute('title', gettext('Показать пароль'));
        icon.classList.remove('icon-eye-off');
        icon.classList.add('icon-eye');
      } else {
        // сейчас пароль не видно
        field.setAttribute('type', 'text');
        this.setAttribute('data-show', '1');
        this.setAttribute('title', gettext('Скрыть пароль'));
        icon.classList.remove('icon-eye');
        icon.classList.add('icon-eye-off');
      }
    });
  });

});
