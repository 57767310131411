import sendData from "./sendData";

function loadPlayer() {
  let links = document.querySelectorAll('.card-player');
  if (links) {
    links.forEach(function (button) {
      button.addEventListener('click', (event) => {
        event.preventDefault();
        const offer = button.getAttribute('data-offer');
        const size = button.getAttribute('data-size');
        let placeholder = button.previousElementSibling;
        const url = Urls['shop-load-player'](offer);

        let ribbon = button.parentNode.querySelector('.corner-ribbon');
        if (!ribbon) {
          let sidebarSection = button.closest('section');
          if (sidebarSection) {
            ribbon = sidebarSection.querySelector('.corner-ribbon');
          }
        }

        sendData('GET', url, {'size': size})
          .then((data) => {
            if (ribbon) {
              try {
                ribbon.parentNode.removeChild(ribbon);
              } catch(e) { console.error('Not found ribbon parent', e); }
            }
            try {
              button.parentNode.removeChild(button);
            } catch (e) { console.error('Not found player button parent', e); }

            placeholder.innerHTML = data.html;
            //  GuitarOnly
            // dataLayer.push({'event': 'playVideo'});
            return false;
          });
        return false;
      });
    });
  }
};

export default loadPlayer;
