import notifier from './notifier';
import sendData from "./sendData";

function addToCart() {
    //const url = Urls['cart-list'](); rest api
    const url = Urls['cart_add']();
    const cart_url = Urls['my_cart']();
    const goToCartMessage = gettext('Перейти в корзину');
    const goToCartShortMessage = gettext('В корзину');
    const addCartButtonList = document.querySelectorAll('.cart-add-button');
    const cartCounterElement = document.getElementById('cart-counter');

    addCartButtonList.forEach(function (button) {
        const offer = button.getAttribute('data-offer');
        const out = button.getAttribute('data-out');
        const parent = button.parentNode;
        button.addEventListener('click', (event) => {
            button.setAttribute('disabled', 'disabled');
            sendData('POST', url, {'shop_offer': offer})
            .then((data) => {
                if (data.success){
                    notifier.success(data.message);
                    cartCounterElement.classList.remove('badge-primary');
                    cartCounterElement.classList.add('badge-orange');
                    cartCounterElement.innerHTML = data.counter;
                    parent.removeChild(button);
                    let message = goToCartMessage;
                    if (out == 'short'){
                        message = goToCartShortMessage;
                    }
                    parent.insertAdjacentHTML(
                        'beforeend',
                        `<a href="${cart_url}" title="${goToCartMessage}" class="dropdown-item btn text-left">${message} >></a>`
                    );
                } else {
                    notifier.alert(data.message);
                    button.removeAttribute('disabled');
                }
            })
        });
    });
};

export default addToCart;
